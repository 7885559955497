let cartUpdateTimeout;

export function cartChanged() {
  let qtyChanged = false;
  let invoiceChanged = false;
  /* Swiss feature, probably not needed */
  const $oneCheckOnly = $('.one-check-only');
  // let productsChanged = false;
  $('form[name=sylius_cart] .iso-spinner input').each(function f() {
    if ($(this).data('new-value') && (parseInt($(this).attr('value'), 10) !== parseInt($(this).data('new-value'), 10))) {
      qtyChanged = true;
    }
  });
  if ($oneCheckOnly.data('new-value') && ($oneCheckOnly.attr('value') !== $oneCheckOnly.data('new-value'))) {
    invoiceChanged = true;
  }
  return qtyChanged || invoiceChanged;
}

export function cartWidgetRefresh(selector = '#cartHeaderWidget') {
  let $cartWidget = $(selector);
  $.ajax({
    method: 'GET',
    url: $cartWidget.data('cart-widget-url'),
    cache: false,
    success(response) {
      $cartWidget.replaceWith(response);
      $cartWidget = $(selector);
      console.log(selector);
      $cartWidget.find('.sylius-cart-remove-button').removeFromCart();
      // $cartWidget.find('.dropdown-toggle').dropdownHover();
      $cartWidget.find('.cart-notif').addClass('refresh-pulse');
      $('.clear-all-products-modal-toggler').on('click', function (e) {
        if (e.originalEvent !== undefined) {
          e.preventDefault();
        }
      });
    },
  });
}

export function setCartRefresh() {
  window.cartRefreshTimeout = setTimeout(() => {
    if (IsoshopConfig.cartRefreshRequired === true) {
      if (window.location.href.indexOf(IsoshopConfig.cartUrl) > -1) {
        window.location.href = IsoshopConfig.cartUrl;
      } else if (window.location.href.indexOf(IsoshopConfig.checkoutUrl) > -1) {
        window.location.href = IsoshopConfig.checkoutUrl;
      } else if (window.location.href.indexOf(IsoshopConfig.completeUrl) > -1) {
        window.location.href = IsoshopConfig.completeUrl;
      }
    }
  }, 1500);
}

export function setCartUpdate() {
  const $cartUpdateBtn = $('#sylius-cart-update');
  const $gotoCheckoutBtn = $('.iso-btn-cart-goto-checkout');
  if ($cartUpdateBtn.length) {
    clearTimeout(cartUpdateTimeout);
    $cartUpdateBtn.addClass('reload-single');
    $gotoCheckoutBtn.addClass('blocked');
    $gotoCheckoutBtn.data('href', $gotoCheckoutBtn.attr('href'));
    $gotoCheckoutBtn.removeAttr('href');
    cartUpdateTimeout = setTimeout(() => {
      if (cartChanged()) {
        $cartUpdateBtn.trigger('click');
      } else {
        $cartUpdateBtn.removeClass('reload-single');
        $gotoCheckoutBtn.removeClass('blocked');
        $gotoCheckoutBtn.attr('href', $gotoCheckoutBtn.data('href'));
      }
    }, 1500);
  }
}

export function initSpinners(update = false) {
  $('.iso-spinner input').on('focus', function f() {
    $(this).select();
  });

  $('body')
    .on('click', '.iso-spinner-btn-up, .iso-spinner-btn-down', function f() {
      const modifier = $(this).hasClass('iso-spinner-btn-up') ? 1 : -1;
      const $spinner = $(this).parents('.iso-spinner');
      const dataInput = $(this).attr('data-input');
      const QTY = $spinner.find(`input.${dataInput}`);
      const dataMax = ($(this).data('maximum')) ? $(this).data('maximum') : 999;
      if ((modifier > 0 && QTY.val() < dataMax) || (modifier < 0 && QTY.val() > 1)) {
        QTY.val(parseInt(QTY.val(), 10) + modifier);
        QTY.data('new-value', QTY.val());
        if (update) setCartUpdate();
      } else {
        QTY.data('new-value', QTY.val());
      }
    })
    .on('input blur', '.iso-spinner input', function f() {
      const QTY = $(this);
      const dataMax = ($(this).data('maximum')) ? $(this).data('maximum') : 999;
      const parsedQTY = parseInt(QTY.val(), 10);
      if (Number.isNaN(parsedQTY)) {
        QTY.val(QTY.data('new-value'));
      } else if (parsedQTY < 1) {
        QTY.val(1);
      } else if (parsedQTY > dataMax) {
        QTY.val(dataMax);
      } else {
        QTY.val(parsedQTY);
      }
      QTY.data('new-value', QTY.val());
      if (update) setCartUpdate();
    });
}

export default function def() {
  initSpinners(1);
}
