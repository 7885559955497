import { isTouchEnabled, productBoxesTouchEvents } from './helpers';
import { handleProductVariantsChange, initVariantsTable } from './variants';
import { setCartRefresh, cartWidgetRefresh } from './cart';

export function productModalInitAndShow(event, element, options) {
  const {
    variantCode = element.data('variant-code'),
    ajaxURL = element.data('ajax-url'),
    removeUrl,
    csrfToken,
  } = options;

  $.get(ajaxURL, options, (modalHTML) => {
    $('#productModal').modal('hide').data('bs.modal', null).remove();
    $('.modal.show').modal('hide');
    $('.modals').append(modalHTML);

    const productModal = $('#productModal');

    if (options.modalType === 'productAdded') {
      if (isTouchEnabled()) {
        productBoxesTouchEvents($('.modal-associations'));
      }
      productModal.find('.buy-btn').on('click', function f(e) {
        productModalInitAndShow(e, $(this), { modalType: 'quickView' });
      });
    }

    if (productModal.find('#sylius-variants-pricing').length > 0) {
      // handleProductOptionsChange();
    } else if (productModal.find('#sylius-product-variants').length > 0) {
      // handleProductVariantsChange(options.quantity);
      handleProductVariantsChange();
      productModal.find('[name="sylius_add_to_cart[cartItem][variant]"]:enabled').first().trigger('click');
    }

    productModal.find('#sylius-product-adding-to-cart').addToCart();

    productModal.on('shown.bs.modal', function () {
      clearTimeout(window.cartRefreshTimeout);
      if (options.modalType === 'productAdded') IsoshopConfig.cartRefreshRequired = true;
      if (variantCode !== undefined) $(this).find(`input[value="${variantCode}"]`).trigger('click');
      if (options.modalType === 'quickView') initVariantsTable(productModal);
      if (options.modalType === 'productAdded' || options.modalType === 'removeProduct') {
        productModal.find('.iso-product-add-cart').remove();
      }
      if (options.modalType === 'removeProduct') {
        console.log('removeModal');
        productModal.find('.product-modal-confirm').on('click', (e) => {
          $.ajax({
            url: removeUrl,
            method: 'POST',
            data: { _csrf_token: csrfToken, _method: 'DELETE' },
            success() {
              if (window.location.href.indexOf(IsoshopConfig.cartUrl) > -1) {
                window.location.href = IsoshopConfig.cartUrl;
              } else if (window.location.href.indexOf(IsoshopConfig.checkoutUrl) > -1) {
                window.location.href = IsoshopConfig.checkoutUrl;
              } else if (window.location.href.indexOf(IsoshopConfig.completeUrl) > -1) {
                window.location.href = IsoshopConfig.completeUrl;
              } else {
                cartWidgetRefresh('#cartMobileWidget');
                cartWidgetRefresh('#cartHeaderWidget');
                productModal.modal('hide').data('bs.modal', null).remove();
              }
            },
          });
          e.preventDefault();
        });
      }
      setTimeout(function () {
        // initQuickviewGallery(productModal);
      }, 500);
      if (typeof observer !== 'undefined') {
        let boxPictures = document.querySelectorAll('#productModal .menu-pic.lazy');
        boxPictures.forEach((pic) => {
          observer.observe(pic);
        });
      }
    }).on('hide.bs.modal', function () {
      if (IsoshopConfig.cartRefreshRequired === true) {
        setCartRefresh();
      }
    }).on('hidden.bs.modal', function () {
      $(this).data('bs.modal', null).remove();
    });

    productModal.modal('show');
  });
  if (event !== null) event.preventDefault();
}

export function clearCartInit() {
  $('.clear-all-products-modal-toggler').on('click', function (e) {
    if (e.originalEvent !== undefined) {
      e.preventDefault();
    }
  });

  $('.clear-all-products-modal').on('show.bs.modal', function (e) {
    var caller = $(e.relatedTarget);
    $(this).find('.simple-modal-confirm').on('click', function () {
      caller.trigger('click');
    });
  });
}
