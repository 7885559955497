import { productModalInitAndShow } from './modals';
import { cartWidgetRefresh } from './cart';

export function initCartManagement($) {
  $.fn.extend({
    removeFromCart() {
      $.each($(this), function f(index, element) {
        const redirectUrl = $(element).data('redirect');
        const csrfToken = $(element).data('csrf-token');
        const removeUrl = $(element).data('url');
        const quantity = $(element).data('quantity');

        $(this).on('click', (e) => {
          console.log('click');
          productModalInitAndShow(e, $(element), {
            modalType: 'removeProduct', quantity, removeUrl, csrfToken,
          });
          e.preventDefault();
        });
      });
    },
    addToCart() {
      $(this).on('submit', function f(e) {
        const element = $(this);
        const href = $(element).attr('action');
        const redirectUrl = $(element).data('redirect');
        const validationElement = $(this).find('#sylius-cart-validation-error');
        const ajaxURL = $(this).find('.iso-btn-add-to-cart').data('ajax-url');
        const checkedVariant = element.find('[name="sylius_add_to_cart[cartItem][variant]"]:checked');
        const variantQuantity = element.find('[name="sylius_add_to_cart[cartItem][quantity]"]');
        const variantQuantityVal = variantQuantity.val();

        $.ajax({
          method: 'POST',
          url: href,
          cache: false,
          data: $(this).serialize(),
          success() {
            validationElement.addClass('hidden');
            validationElement.removeClass('mb-3');
            cartWidgetRefresh('#cartMobileWidget');
            cartWidgetRefresh('#cartHeaderWidget');
            productModalInitAndShow(null, $(this), {
              modalType: 'productAdded',
              variantCode: checkedVariant.val(),
              quantity: variantQuantityVal,
              ajaxURL,
            });
          },
          error(response) {
            const { responseJSON } = response;
            validationElement.removeClass('hidden');
            let validationMessage = '';

            $.each(responseJSON.errors.errors, (key, message) => {
              validationMessage += message;
            });
            validationElement.addClass('mb-3');
            validationElement.html(`<span class="material-symbols-outlined">error</span> &nbsp;${validationMessage}`);
            $(element).removeClass('loading');
          },
        });
        e.preventDefault();
      });
    },
  });
}

export default function initApp($) {
  initCartManagement($);
  $('.sylius-cart-remove-button').removeFromCart();
  $('#sylius-product-adding-to-cart').addToCart();
}
