import jQuery from './jquery';
import initApp from './modules/app';
import initCookie from './modules/cookiealert';
import { initVariantsTable, handleProductVariantsChange } from './modules/variants';
import { initCheckboxVisibilityToggle, taxonsAndFiltersExpansion, rewriteAddressBookDataToForm, productBoxesTouchEvents, isTouchEnabled, initContactForm } from './modules/helpers';
import { initSpinners } from './modules/cart';
import { productModalInitAndShow, clearCartInit } from './modules/modals';
import initInpost from './modules/inpost';
import { setSearchBox, extendAutocomplete, searchInit } from './modules/search';

window.jQuery = jQuery;
window.$ = window.jQuery;

let navTimer;

(() => {
  $.fn.isInViewport = function f() {
    const elementTop = $(this).offset().top;
    const elementBottom = elementTop + $(this).outerHeight();
    const viewportTop = $(window).scrollTop();
    const viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
  };

  $(document).ready(() => {
    initApp($);
    initCookie();

    /* menu main links handling (clicking and hovering) */
    $('html.no-touch #mainNavbar .nav-item.dropdown').on('click', function f() {
      if ($(this).find('a').attr('href') !== '#') window.location.href = $(this).find('a').attr('href');
    }).on('mouseenter', function me() {
      const $anchor = $(this).children('a');
      clearTimeout(navTimer);
      $('#mainNavbar a.dropdown-toggle').removeClass('show');
      setSearchBox(false);
      navTimer = setTimeout(function t() {
        console.log($anchor);
        $anchor.addClass('show').next('ul').addClass('show');
        $('.top-bar').addClass('expanded');
        $('.cta-link.lazy-bg').removeClass('lazy-bg');
        if (typeof observer !== 'undefined') {
          let menuPictures = document.querySelectorAll('.menu-pic.lazy');
          menuPictures.forEach((pic) => {
            observer.observe(pic);
          });
        }
      }, 250);
    }).on('mouseleave', function ml() {
      const $anchor = $(this).children('a');
      clearTimeout(navTimer);
      $anchor.removeClass('show').next('ul').removeClass('show');
      navTimer = setTimeout(function () {
        $('.top-bar').removeClass('expanded');
      }, 250);
    });

    handleProductVariantsChange();
    initVariantsTable();
    initSpinners(1);
    taxonsAndFiltersExpansion();
    rewriteAddressBookDataToForm();
    /* toggles showing additional address form */
    initCheckboxVisibilityToggle();
    clearCartInit();
    extendAutocomplete(window.$);
    searchInit(navTimer);

    if (isTouchEnabled()) {
      productBoxesTouchEvents($('.list-product-area'));
      productBoxesTouchEvents($('.iso-section-products'));

      $('body').on('touchstart', (e) => {
        if (!$(e.target).closest('.shop-product-card').length) {
          $('.shop-product-card').removeClass('hovered');
        }
      });
    }

    if ($('.contact-form').length) initContactForm();
    if ($('#selectInpostPointModal').length) initInpost();

    /* quickview modal open */
    $('.shop-product-card .buy-btn, .saved-cart-link, .cart-item-link').on('click', function f(e) {
      productModalInitAndShow(e, $(this), { modalType: 'quickView' });
    });

    /* user account current page highlighted in menu */
    $('.iso-page-account .shop-account-menu .nav-link').each(function f() {
      const currentURL =  window.location.href;
      if (currentURL.indexOf($(this).attr('href')) !== -1) {
        $(this).addClass('active').attr('aria-current', 'page');
      }
    });

    const filtersSelected = $('#mobileFilters .filters-widget .filter-option-selected').length;
    if (filtersSelected > 0) {
      $('#headingFilters .material-symbols-outlined').after(`<span class="fw-bold">(${filtersSelected})</span>`);
    }

    $('.app-icons .app-name').each(function () {
      $(this).attr('style', `--offset:-${$(this).width() - $(this).parent().width()}px`);
    });

    const $shippingCalculator = $('.iso-shipping-calculator');
    $shippingCalculator.find('input[type="radio"]').on('click change', function sc() {
      $($shippingCalculator.data('total-selector')).html($(this).parents('.radio-container').find('.extra').data('order-total'));
      $($shippingCalculator.data('shipping-payment-selector')).html($(this).parents('.radio-container').find('.extra').data('shipping-payment'));
    });

    if($(window).width() <= 831) {
      $('.nav-link.dropdown-toggle').removeAttr('data-bs-hover');
      $('.nav-link.dropdown-toggle').attr('data-bs-toggle', 'dropdown');
    }
  });

  $(document).on('keydown', 'form.hm-searchbox', event => event.key !== 'Enter');

  $(window).on('load', () => {
    /* scroll to first form error if not in viewport */
    const $invalid = $('.is-invalid');
    if ($invalid.length) {
      if (!$invalid.isInViewport()) {
        $('html, body').animate({
          scrollTop: $invalid.first().offset().top - 140,
        }, 1000);
      }
    }
  }).scroll(() => {
    if ($(window).scrollTop() > 20) {
      $('.top-bar').addClass('page-scrolled', 300);
    } else {
      $('.top-bar').removeClass('page-scrolled', 300);
    }
  });
})();
