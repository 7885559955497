export default function initInpost() {
  const selectInpostPointModal = $('#selectInpostPointModal');

  $(".radio-container[data-isoshop-type='inpost'] input").on('change', () => {
    selectInpostPointModal.modal('show');
  });

  $('#inpost-point-change').on('click', () => {
    selectInpostPointModal.modal('show');
  });

  selectInpostPointModal.on('show.bs.modal', (e) => {
    let map;

    if (!$('#easypack-map').hasClass('easypack-widget')) {
      easyPack.init({
        defaultLocale: 'pl',
        closeTooltip: false,
        points: {
          types: ['parcel_locker'],
        },
        map: {
          googleKey: 'AIzaSyB1V4k85zpTZCG5As6tZDP_22_zeADlQTQ',
          defaultLocation: [51.507351, -0.127758],
        },
      });
      map = easyPack.mapWidget('easypack-map', (point) => {
        console.log(point);

        $('#inpost-details').show();
        $('#inpost-point-details-name').html(point.name);
        $('#inpost-point-details-address').html(`${point.address.line1}, ${point.address.line2}`);

        selectInpostPointModal.modal('toggle');

        const shipmentData = {
          inpostPointName: point.name,
        };
        $('#isosylius_onepage_checkout_shipments_0_shipmentData').val(JSON.stringify(shipmentData));
      });
    }
  });
}
