export function initCheckboxVisibilityToggle() {
  $('.iso-toggle-hide').each(function f() {
    let speed = $(this).data('animation-speed');
    const $object = $(`${$(this).data('object-selector')}`).hide();

    if (typeof speed === 'undefined') {
      speed = 'slow';
    }

    const $checkbox = $(this).find('input[type=checkbox]').on('change', () => {
      if ($($object).is(':hidden')) {
        $($object).show(speed);
      } else {
        $($object).slideUp(speed);
      }
    });

    if ($checkbox.attr('checked')) {
      $($object).show();
    }
  });
}

export function taxonsAndFiltersExpansion() {
  $('.widget-item button, #mobileCategories .dropdown button').on('click', function f() {
    const $a = $(this);
    if ($a.hasClass('collapsed')) {
      $a.find('span').text('expand_more');
    } else {
      $a.find('span').text('expand_less');
    }
  });

  $('.dropdown-taxon').on('shown.bs.collapse', (e) => {
    e.stopPropagation();
  });

  $('.shop-widget .collapse.active').each(function f() {
    const $activeTaxon = $(this);
    $activeTaxon.prev().find('button').trigger('click');
    $activeTaxon.removeClass('active');
  });

  $('#mobileCategories').on('shown.bs.collapse', function c() {
    $(this).find('a.nav-link').each(function a() {
      const anchor = $(this);
      if (window.location.href.includes(anchor.attr('href'))) {
        if (anchor.siblings('button').hasClass('collapsed')) anchor.siblings('button').trigger('click');
      }
    });
  });
}

export function rewriteAddressBookDataToForm() {
  $('.address-book-select li').on('click', function f() {
    const $addressSelected = $(this);
    const $form = $addressSelected.parents('.a-form');
    const $inputs = $form.find('input');

    $.each($inputs, (key, input) => {
      $(input).val('');
    });

    const parseKey = function (key) {
      return key.replace(/(_\w)/g, m => m[1].toUpperCase());
    };

    $.each($addressSelected.data(), (key, property) => {
      if (key === 'countryCode') {
        $form.find(`select[name*=${parseKey(key)}]`).val(property);
      } else if (key === 'isoshopVatIdNumber') {
        $form.find('input[name*=isoshopVATIdNumber]').val(property);
      } else {
        $form.find(`input[name*=${parseKey(key)}]`).val(property);
      }
    });
  });
}

export function productBoxesTouchEvents(container = $('body')) {
  container.find('.shop-product-card').on({
    touchstart() {
      if (!$(this).hasClass('hovered')) {
        container.find('.shop-product-card').removeClass('hovered');
      }
    },
    touchend(e) {
      if (!$(this).hasClass('moving')) {
        if (!$(this).hasClass('hovered')) {
          e.preventDefault();
          container.find('.shop-product-card').removeClass('hovered');
          $(this).addClass('hovered');
        }
      } else {
        $(this).removeClass('moving');
      }
    },
    touchmove() {
      $(this).addClass('moving');
    },
  });
}

export function isTouchEnabled() {
  return ('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0);
}

export function initContactForm(containerSelector = '.contact-form') {
  $('body').on('submit', `${containerSelector} form`, function f(e) {
    e.preventDefault();
    const $currentForm = $(this);
    $currentForm.find('button[type=submit]').attr('disabled', true);
    $.ajax({
      type: $currentForm.attr('method'),
      url: $currentForm.attr('action'),
      data: $currentForm.serialize(),
      dataType: 'json',
    })
      .done((data) => {
        $currentForm.parent().html(data.html);
        if (typeof data.ok !== 'undefined' && data.ok) {
          $(':input', `${containerSelector} form`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .removeAttr('checked')
            .removeAttr('selected');
        }
        $currentForm.find('button[type=submit]').removeAttr('disabled');
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        if (typeof jqXHR.responseJSON !== 'undefined') {
          if (jqXHR.responseJSON.hasOwnProperty('form')) {
            $(containerSelector).html(jqXHR.responseJSON.form);
          }
          $(containerSelector).append(jqXHR.responseJSON.message);
        } else {
          alert(errorThrown);
        }
        $currentForm.find('button[type=submit]').removeAttr('disabled');
      });
  });
}

export default function def() {
  initCheckboxVisibilityToggle();
}
