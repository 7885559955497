let searchResultsToBoxFocus = false;

export function posEnd(el) {
  const len = el.value.length;
  const t = el.createTextRange;

  if (el.setSelectionRange) {
    el.focus();
    el.setSelectionRange(len, len);
  } else if (el.createTextRange) {
    t.collapse(true);
    t.moveEnd('character', len);
    t.moveStart('character', len);
    t.select();
  }
}

export function setSearchBox(state) {
  if (state) {
    $('.top-bar').addClass('expanded');
    $('.search-box').addClass('inside');
  } else {
    $('.top-bar').removeClass('expanded');
    $('.search-box').removeClass('inside');
  }
}

export function searchInit(navTimer) {
  // temporarily here - move outside
  $('#mainNavbar').on('shown.bs.collapse', function () {
    $(this).parents('.top-bar').addClass('expanded');
  }).on('hidden.bs.collapse', function () {
    $(this).parents('.top-bar').removeClass('expanded');
  });

  $(document).on('keydown', 'form.hm-searchbox', event => event.key !== 'Enter');

  $('#isoshopSearch').on('keyup focusin', function is(e) {
    if ($('#mainNavbar').hasClass('show')) {
      $('#mainNavToggler').trigger('click');
    }
    setTimeout(() => setSearchBox(true), 500);
    if (e.type === 'keyup' && searchResultsToBoxFocus) {
      posEnd(this);
      $(this).removeClass('caret-trans');
      searchResultsToBoxFocus = false;
    }
    if ([37, 38, 39, 40].indexOf(e.which) < 0) {
      const textLength = $(this).val().length;
      if (textLength >= 2) {
        $('#isoshopAutocompleteResults').addClass('loading');
        const filteredQuery = $(this).val().replace('&', '');
        $.get('/swisssoft/shop-search.html', { query: filteredQuery })
          .done((data) => {
            clearTimeout(navTimer);
            $('#isoshopAutocompleteResults').html(data).removeClass('loading');
            $('.result-item').each(function ri() {
              $(this).on('mouseover', function () {
                $('.result-item.focused').removeClass('focused');
                $(this).addClass('focused')
                  .focus();
              }).on('mouseleave', function () {
                $(this).removeClass('focused')
                  .blur();
              });
            });
            $('.autocomplete-menu')
              .on('keydown', (k) => {
                const focused = $('.result-item.focused');
                switch (k.which) {
                  case 13:
                    window.location.href = focused.find('a').attr('href');
                    break;
                  case 38:
                    if (focused.prev().length) {
                      focused.removeClass('focused').prev()
                        .addClass('focused')
                        .focus();
                    } else if (!(window.innerWidth > 767) && focused.closest('div').prev().length) {
                      focused.removeClass('focused').closest('div').prev().find('.result-item')
                        .last()
                        .addClass('focused')
                        .focus();
                    } else {
                      searchResultsToBoxFocus = true;
                      $('#isoshopSearch').addClass('caret-trans').focus();
                    }
                    break;
                  case 40:
                    if (focused.next().length) {
                      focused.removeClass('focused').next()
                        .addClass('focused')
                        .focus();
                    } else if (!(window.innerWidth > 767) && focused.closest('div').next().length) {
                      focused.removeClass('focused').closest('div').next().find('.result-item')
                        .first()
                        .addClass('focused')
                        .focus();
                    }
                    break;
                  case 37:
                    if (window.innerWidth > 767) {
                      if ($('.cat-part').length) {
                        focused.removeClass('focused');
                        $('.cat-part .result-item').first().focus()
                          .addClass('focused');
                      }
                    }
                    break;
                  case 39:
                    if (window.innerWidth > 767) {
                      if ($('.prod-part').length) {
                        focused.removeClass('focused');
                        $('.prod-part .result-item').first().focus()
                          .addClass('focused');
                      }
                    }
                    break;
                  default:
                }
              });
          });
      } else {
        $('#isoshopAutocompleteResults').empty();
      }
    } else if (e.which === 40 && !$('#isoshopAutocompleteResults').hasClass('loading')) {
      if ($('#isoshopAutocompleteResults .prod-part').length) {
        $('.prod-part li').first().addClass('focused').focus();
      } else if ($('#isoshopAutocompleteResults .cat-part').length) {
        $('.cat-part li').first().addClass('focused').focus();
      }
    }
  });

  $('body').on('click', (e) => {
    if (!$(e.target).closest('.search-box').length && $('.search-box').hasClass('inside')) {
      setSearchBox(false);
    }
  });

  $('#searchToggle').on('click', () => {
    if ($('#mainNavbar').hasClass('show')) {
      $('#mainNavToggler').trigger('click');
    }
    if ($('.search-box').hasClass('inside')) {
      setSearchBox(false);
    } else {
      $('#isoshopSearch').focusin();
    }
  });

  $('.search-btn.dummy-btn').on('click', () => {
    $('#isoshopSearch').focus();
  });

  $('.navbar-toggler').on('click', () => {
    setSearchBox(false);
  });

  $('.nav-bg-overlay').on('click', () => {
    if ($('#mainNavbar').hasClass('show')) {
      $('#mainNavToggler').trigger('click');
    } else {
      $('.top-bar').removeClass('expanded');
    }
  });

  $(window).on('keydown', (e) => {
    if (([32, 37, 38, 39, 40].indexOf(e.which) > -1) && $('.search-box').hasClass('inside') && (!$('#isoshopSearch').is(':focus'))) {
      e.preventDefault();
    }
  });
}

export function extendAutocomplete($) {
  (function ($) {
    $.fn.extend({
      autocompleteSearch(autocompleteInputElement, apiEndpointPath) {
        $(autocompleteInputElement)
          .search({
            type: 'category',
            minCharacters: 3,
            debug: true,
            verbose: true,
            apiSettings: {
              onResponse(autocompleteResponse) {
                const response = { results: {} };

                $.each(autocompleteResponse.items, (index, item) => {
                  const taxonName = item.taxon_name;
                  const maxResults = 15;

                  if (index >= maxResults) {
                    return false;
                  }

                  if (response.results[taxonName] === undefined) {
                    response.results[taxonName] = {
                      name: taxonName,
                      results: [],
                    };
                  }

                  response.results[taxonName].results.push({
                    title: item.name,
                    description: item.description,
                    url: item.slug,
                    price: item.price,
                    image: item.image,
                  });
                });

                return response;
              },
              url: apiEndpointPath,
            },
          });
      },
    });
  }(jQuery));
}
