export function initVariantsTable($container = $('body')) {
  if ($container instanceof jQuery) {
    const $linkMore = $container.find('.btn-more-variants');
    const $linkLess = $container.find('.btn-less-variants');
    const variantClass = '.variant-wrapper';

    $linkMore.each(function f() {
      $(this).on('click', function g() {
        const $variantTable = $(this).parents(variantClass);
        $variantTable.addClass('open');
      });
    });
    $linkLess.each(function f() {
      $(this).on('click', function g() {
        const $variantTable = $(this).parents(variantClass);
        $variantTable.removeClass('open');
      });
    });

    const variantSelector = '[name="sylius_add_to_cart[cartItem][variant]"]';

    if ($container.find(`${variantSelector}:checked`).is(':disabled')) {
      if ($container.find(`${variantSelector}:enabled`).length > 0) {
        $container.find(`${variantSelector}:enabled`).first().trigger('click');
      } else if ($container.find('.variant-wrapper').length > 0) {
        $container.find(variantSelector).prop('checked', false);
        $container.find('.iso-spinner').parent().nextAll().remove();
        $container.find('.iso-spinner').parent().remove();
        $container.find('.prod-price').remove();
      }
    }

    const $checkedVariant = $container.find(`${variantSelector}:checked`);
    $checkedVariant.trigger('change');
    if ($checkedVariant.parents('tr').index() > 3) {
      $linkMore.trigger('click');
    }
  } else {
    throw new Error('Variable "container" is not an instance of jQuery');
  }
}

export function handleProductVariantsChange() {
  $('[name="sylius_add_to_cart[cartItem][variant]"]').on('change', function f(event) {
    const $prodInfo = $(this).parents('.product-info');
    const $variantRow = $(this).parents('tr');
    const $variantRowPrice = $variantRow.find('.sylius-product-variant-price');
    // var $price = $(this).parents('tr').find('.sylius-product-variant-price').html();
    const priceMain = $variantRowPrice.data('price-main');
    const priceCents = $variantRowPrice.data('price-cents');
    const details = $variantRow.find('td').first()
      .find('.short-variant-name')
      .html();
    const shipmentTime = $variantRow.find('.product-shipment-time').html();
    const originalPriceMain = $variantRowPrice.siblings('.sylius-product-variant-price-old')
      .data('original-price-main');
    const originalPriceCents = $variantRowPrice.siblings('.sylius-product-variant-price-old')
      .data('original-price-cents');

    const variantCode = $(event.currentTarget).val();

    /*if (quantity !== null) {
      const tierpriceContainer = getTierpriceContainer(variantCode, quantity);

      if (tierpriceContainer !== null) {
        priceMain = tierpriceContainer.data('price-main');
        priceCents = tierpriceContainer.data('price-cents');
      }
    }*/

    $prodInfo.find('.product-price .price-main').html(priceMain);
    $prodInfo.find('.product-price .price-cents').html(priceCents);
    $prodInfo.find('.price-details').html(`<span> ${details}</span>`);
    $prodInfo.find('.btn-group + .product-shipment-time').html(shipmentTime);
    $prodInfo.find('.variant-code').html(variantCode);
    // $prodInfo.find('#product-price').find('br').remove();

    const $prodPriceCut = $prodInfo.find('.product-price .price-cut');

    if (originalPriceMain) {
      $prodPriceCut.find('.price-main').html(originalPriceMain);
      $prodPriceCut.find('.price-cents').html(originalPriceCents);
      $prodPriceCut.removeClass('d-none');
    } else {
      $prodPriceCut.find('.price-main').html('');
      $prodPriceCut.find('.price-cents').html('');
      $prodPriceCut.addClass('d-none');
    }

    $prodInfo.find('.iso-btn-quickview-more').attr('href', $(this).attr('url'));
    const { hash } = window.location;
    const variantValue = $(`#isoshop-variant-links [data-variant-code="${variantCode}"]`).data('value');
    const path = (typeof variantValue === 'string' ? variantValue : '') + hash;

    if (path !== '') {
      const nextTitle = `${document.title} - ${details}`;
      const nextState = { additionalInformation: 'Updated the URL with JS' };
      window.history.replaceState(nextState, nextTitle, path);
    }
  });
}

export default function def() {
  initVariantsTable();
  handleProductVariantsChange();
}
